import Cookie from 'js-cookie';

export const stringToLetters = (text: string) => {
  let wordLength = text.split(' ');

  return wordLength.map((i, k) => {
    return (
      <span key={k} className="word">
        {i}
      </span>
    );
  });
};

export const setCookie = (key: string, cookie: string) => {
  Cookie.set(key, cookie);
};

export const removeCookie = (key: string) => {
  Cookie.remove(key);
};

export const getRequestCookies = (ctx: any, key: string) => {
  return ctx?.req?.cookies?.[key];
};

export const getCookie = (key: string) => {
  return Cookie.get(key);
};

export const encodeTitle = (str: string) => {
  return encodeURI(
    str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .replace(/\s+/g, '-')
  );
};

export const getByString = (path: string, obj: any) => {
  return path?.split('.').reduce((p, c) => (p && p[c]) || null, obj);
};

const formatter = new Intl.NumberFormat('ru', {
  style: 'decimal',
});

export const formatNumbers = (n: number) => {
  return formatter.format(n);
};

export const mapNum = (
  x: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) => {
  return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const throttle = <T,>(fn: (...args: T[]) => void, wait: number) => {
  let time = Date.now();
  return function (...args: T[]) {
    if (time + wait - Date.now() < 0) {
      fn(...args);
      time = Date.now();
    }
  };
};
