type PushDataLayer = {
  event: string;
  target: EventTarget | null;
  element: (EventTarget & Element) | null;
  [key: string]: unknown;
};

export const pushDataLayer = ({
  event,
  target,
  element,
  ...rest
}: Partial<PushDataLayer>) => {
  window.dataLayer = window.dataLayer || [];
  const obj = {
    event,
    target: null,
    element: null,
    elementClasses: null,
    elementText: null,
    pageURL: null,
    popupTitle: null,
    scrollHeight: null,
    docTitle: null,
    docScroll: null,
    toolkitBlockTitle: null,
    pushDataLayer: null,
    sliderValue: null,
    fileId: null,
    ...rest,
  };
  if (target) {
    Object.assign(obj, {
      target: target,
    });
  }
  if (element) {
    Object.assign(obj, {
      element,
      elementClasses: element.className,
      elementText: element.textContent,
    });
  }
  if (location?.href) {
    Object.assign(obj, {
      pageURL: location.href,
    });
  }
  const scrollPercent = (
    (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
    100
  ).toFixed(2);
  Object.assign(obj, {
    scrollHeight: `${scrollPercent}%`,
  });
  window.dataLayer.push(obj);
};
