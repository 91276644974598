import { memo } from 'react';
import template from 'lodash.template';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';
import { getByString } from '@/helpers/_functions';

type TranslateProps = {
  val: string;
  vars: { [key: string]: string | number } | string;
  selectedLang: string;
  languages: Record<string, string>;
};

const Translate = memo((props: TranslateProps) => {
  const str = getByString(props.val, props.languages[props.selectedLang]);
  const compiled = str && template(str);

  return (compiled && compiled(props.vars)) || props.val || null;
});

const withContext = withLanguageContext(Translate, '*') as unknown as (
  props: Omit<TranslateProps, 'selectedLang' | 'languages'>
) => string;

export default withContext;
