'use client';

import { createContext } from 'react';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
  const enableLoading = () => {
    this.setState({ loading: true });
  };

  const subscribe = async (data) => {
    return await api.post('subscribe', data);
  };

  const sendQuiz = async (data) => {
    return await api.post('send-quiz', data);
  };

  const methods = {
    enableLoading,
    subscribe,
    sendQuiz,
  };

  return (
    <DataContext.Provider value={{ ...methods }}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
