import { forwardRef, MouseEventHandler, PropsWithChildren } from 'react';
import styled, { CSSProperties } from 'styled-components';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helpers
import { stringToLetters } from '@/helpers';

//* Components
import Translate from '../Translate';

const Wrapper = styled.p`
	@layer default {
		font-family: var(--urbanist);
		color: var(--dark100);
	}
`;

type TextProps = PropsWithChildren & {
	text?: string;
	vars?: { [key: string]: string | number };
	tag?: string;
	style?: CSSProperties;
	className?: string;
	onClick?: MouseEventHandler;
	onMouseEnter?: MouseEventHandler;
	attr?: unknown[];
	translate: (str?: string) => string;
	itemProp?: string; 
};

const Text = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
	let string = (
		<Translate
			val={props.text || ''}
			vars={props.vars || ''}
		/>
	);

	return (
		<Wrapper
			itemProp={props.itemProp}
			ref={ref}
			as={props.tag}
			style={props.style}
            className={`comp-text ${props.className || ''}`}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			{...props.attr}>
			{props.tag == 'string' ? stringToLetters(props.translate(props.text)) : props.children || string}
		</Wrapper>
	);
});

Text.displayName = 'Text';

export default withLanguageContext<TextProps>(Text, ['translate']);
